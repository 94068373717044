import React, { useEffect, useMemo, useRef } from 'react';
import styles from './index.module.less';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { Rate } from 'antd';
import { imgUrl } from '@/shared/utils';

const CustomersTalkUsContent = () => {
  const { t } = useTranslation('common');
  const containerRef = useRef(null);
  const router = useRouter();
  const { locale } = router;

  const dataList = useMemo(() => [
    {
      name: 'Sophia***',
      desc: t(
        "As a mom, I love DolphinRadar. It gives me all the info I need to ensure my kids are safe on Instagram. The detailed reports are invaluable. It's worth every penny.",
      ),
      rate: 5,
    },
    {
      name: 'Ethan***',
      desc: t(
        `I_used_DolphinRadar_due_to_trust_issues_and_found_out_my_partner_was_just_following_meme_accounts`,
      ),
      rate: 5,
    },
    {
      name: 'Olivia***',
      desc: t(
        "Love it! DolphinRadar gives me detailed insights into my favorite celebs' Instagram activities. It's like having a backstage pass! Highly recommend it.",
      ),
      rate: 5,
    },
    {
      name: 'Michael***',
      desc: t(
        'Love the insights DolphinRadar provides, but the interface is a bit clunky. Luckily, support guided me through it smoothly.',
      ),
      rate: 4,
    },
    {
      name: 'Johnson***',
      desc: t(
        'DolphinRadar is very detailed—maybe too much for casual users like me. Works well, though.',
      ),
      rate: 4.5,
    },
    {
      name: 'Davis***',
      desc: t(
        "As a digital marketer, DolphinRadar helps me track competitors' Instagram activities. It's a secret weapon for staying ahead!",
      ),
      rate: 4.5,
    },
  ], [])

  // useEffect(() => {
  //   const container = containerRef.current;

  //   const scrollStep = 1;
  //   const scrollInterval = 30;

  //   const scroll = () => {
  //     container.scrollLeft += scrollStep;
  //     if (container.scrollLeft + container.clientWidth >= container.scrollWidth) {
  //       container.scrollLeft = 0;
  //     }
  //   };

  //   const intervalId = setInterval(scroll, scrollInterval);

  //   // 清理定时器
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, []);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const scrollStep = 1;
    const scrollInterval = 30;

    let isUserScrolling = false;

    const scroll = () => {
      if (!isUserScrolling) {
        container.scrollLeft += scrollStep;
        if (container.scrollLeft + container.clientWidth >= container.scrollWidth) {
          container.scrollLeft = 0;
        }
      }
    };

    const handleTouchStart = () => {
      isUserScrolling = true;
    };

    const handleTouchEnd = () => {
      isUserScrolling = false;
    };

    // 添加触摸事件监听器
    container.addEventListener('touchstart', handleTouchStart);
    container.addEventListener('touchend', handleTouchEnd);

    const intervalId = setInterval(scroll, scrollInterval);

    // 清理定时器和事件监听器
    return () => {
      clearInterval(intervalId);
      container.removeEventListener('touchstart', handleTouchStart);
      container.removeEventListener('touchend', handleTouchEnd);
    };
  }, []);

  return (
    <>
      <div className={styles.content}>
        <h2>{t('OurCustomersTalkAboutUs')}</h2>
        <p className={styles.titleDesc}>
          {t(
            "To ensure the protection of our users' privacy, we've chosen to redact certain personal details from their reviews.",
          )}
        </p>
        <div className={styles.container} ref={containerRef}>
          {dataList.map((item, index) => {
            return (
              <div className={styles.cardContainer} key={index + item.name}>
                <div className={styles.userContainer}>
                  <Image
                    src={imgUrl(`https://res-gpts.pumpsoul.com/img/${item.name}.png`, 'small')}
                    alt={item.name}
                    className={styles.icon}
                    width={46}
                    height={46}
                  />
                  <span className={styles.userName}>{item.name}</span>
                </div>
                <Rate className={styles.star} allowHalf disabled defaultValue={item.rate} />
                <div className={styles.desc}>{item.desc}</div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default CustomersTalkUsContent;
